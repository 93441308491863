import React from 'react';

import theme from '../../../utils/styling/theme';

// Types
import { SVGAttributes } from 'react';

type Props = {} & SVGAttributes<SVGElement>;

const PinterestIcon = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 31" {...props}>
      <path
        d="M29.449 14.662c0 8.06-6.581 14.594-14.699 14.594-8.118 0-14.699-6.534-14.699-14.594C.051 6.601 6.632.067 14.75.067c8.118 0 14.699 6.534 14.699 14.595"
        fill={theme.color.white}
        stroke={theme.color.white}
      />

      <path
        d="M14.733 1.686c-7.217 0-13.068 5.809-13.068 12.976 0 5.497 3.444 10.192 8.305 12.082-.114-1.026-.217-2.601.046-3.722.237-1.012 1.532-6.45 1.532-6.45s-.391-.777-.391-1.926c0-1.804 1.054-3.151 2.365-3.151 1.115 0 1.653.831 1.653 1.828 0 1.113-.713 2.777-1.082 4.32-.308 1.292.652 2.345 1.935 2.345 2.323 0 4.108-2.432 4.108-5.942 0-3.107-2.248-5.279-5.458-5.279-3.719 0-5.901 2.769-5.901 5.631 0 1.115.433 2.311.972 2.961a.385.385 0 0 1 .091.372c-.099.41-.32 1.292-.363 1.472-.057.237-.189.288-.437.173-1.632-.754-2.653-3.124-2.653-5.027 0-4.093 2.996-7.852 8.635-7.852 4.533 0 8.056 3.208 8.056 7.494 0 4.472-2.839 8.071-6.781 8.071-1.324 0-2.569-.683-2.995-1.49 0 0-.655 2.478-.814 3.085-.295 1.127-1.092 2.539-1.625 3.401 1.223.376 2.523.579 3.87.579 7.217 0 13.068-5.809 13.068-12.975 0-7.167-5.851-12.976-13.068-12.976"
        id="pinterest"
        fill={theme.color.grey}
      />
    </svg>
  );
};

export default PinterestIcon;
