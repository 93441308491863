import styled from '../../../../utils/styling/styled';

import RugAward from '../../RugItems/RugAward';
import styleUtils from '../../../../utils/styling/styleUtils';

export const Award = styled(RugAward)`
  position: absolute;
  z-index: 1;

  right: 0px;
  top: -30px;

  ${styleUtils.media.tablet.large} {
    top: -30px;
  }

  ${styleUtils.media.orientation.landscape} {
    right: -30px;

    ${styleUtils.media.desktop.huge} {
      top: -70px;
    }
  }

  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.media.desktop.huge} {
      top: -50px;
    }
  }
`;

export const Wrapper = styled.div`
  /* Flex Container */
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: relative;

  ${styleUtils.media.orientation.portrait} {
    width: 90vw;

    ${styleUtils.media.tablet.small} {
      flex: 1 1 32%;
    }
  }

  ${styleUtils.media.orientation.landscape} {
    margin-right: 4rem;
  }
`;
