import React from 'react';

import * as S from './styles';

// Types
import { HTMLAttributes } from 'react';

type Props = {
  asExternal?: boolean;
  url: string;
} & HTMLAttributes<HTMLAnchorElement>;

const RugFacebookButton = ({ url, ...rest }: Props) => {
  return (
    <S.Button url={url} {...rest}>
      <S.Icon />
    </S.Button>
  );
};

export default RugFacebookButton;
