import React from 'react';
import idx from 'idx';
import { graphql } from 'gatsby';

import RugPageRug from '../../../../components/Rug/RugPage/RugPageRug';
import isRugNew from '../../../../utils/rug/isRugNew';

// Types
import { RugPageRugContainerFragment } from './__generated__/RugPageRugContainerFragment';

export const fragment = graphql`
  fragment RugPageRugContainerFragment on CMS_Rug {
    award
    created_at
    id
    picture {
      ...SectionLogoPictureFragment
    }
  }
`;

type Props = {
  rug: RugPageRugContainerFragment;
};

const RugPageRugContainer = ({ rug }: Props) => {
  const picture = idx(rug, _ => _.picture.file.childImageSharp.fluid);

  if (!picture) {
    return null;
  }

  const isNew = isRugNew(rug.created_at) || false;

  return <RugPageRug award={rug.award} id={rug.id} isNew={isNew} picture={picture} />;
};

export default RugPageRugContainer;
