import idx from 'idx';

import buildRugVariationsPageUrl from '../../../utils/url/buildRugVariationsPageUrl';
import notNullOrUndefined from '../../../utils/misc/notNullOrUndefined';

type GetVariationsUrlInput = {
  collectionSlug: string;
  id: string;
  primaryRug?: ({ id: string; isVisibleOnWebsite?: boolean | null } | null) | null;
  secondaryRugs?: ({ id: string; isVisibleOnWebsite?: boolean | null } | null)[] | null;
};

export const getVariationsUrl = ({ collectionSlug, id, primaryRug, secondaryRugs }: GetVariationsUrlInput) => {
  const hasVisiblePrimaryRug = idx(primaryRug, _ => _.isVisibleOnWebsite) || false;
  const visibleSecondaryRugsCount = (secondaryRugs || [])
    .filter(notNullOrUndefined)
    .filter(secondaryRug => idx(secondaryRug, _ => _.isVisibleOnWebsite)).length;

  if (!hasVisiblePrimaryRug && visibleSecondaryRugsCount === 0) {
    return null;
  }

  if (hasVisiblePrimaryRug && notNullOrUndefined(primaryRug)) {
    const variationsUrl = `${buildRugVariationsPageUrl({ id: primaryRug.id, slug: collectionSlug })}#${id}`;

    return variationsUrl;
  }

  const variationsUrl = buildRugVariationsPageUrl({ id, slug: collectionSlug });

  return variationsUrl;
};
