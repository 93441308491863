import React from 'react';

import Heading from '../../Heading';
import RugPageMeta from './RugPageMeta';
import Section from '../../Heading/Section';
import SectionOverviewIntro from '../../Section/SectionOverviewIntro';

import * as S from './styles';

// Types
import { ReactNode } from 'react';

type Props = {
  appInfo: ReactNode;
  appInfoPortrait: ReactNode;
  color: string | null;
  facebookUrl: string | null;
  footer: ReactNode;
  material: string | null;
  pinterestUrl: string | null;
  rug: ReactNode;
  title?: string;
  titleHeaderPortrait: ReactNode;
  twitterUrl: string | null;
  variationsUrl?: string | null;
};

const RugPage = ({
  appInfo,
  color,
  facebookUrl,
  footer,
  material,
  pinterestUrl,
  rug,
  title,
  titleHeaderPortrait,
  twitterUrl,
  variationsUrl,
}: Props) => {
  return (
    <>
      {!!title && <Heading isVisuallyHidden={true}>{title}</Heading>}

      <Section hasNoTag={true}>
        <Heading isVisuallyHidden={true}>Rug Information</Heading>

        <SectionOverviewIntro appInfo={appInfo} titleHeaderPortrait={titleHeaderPortrait}>
          <RugPageMeta
            color={color}
            facebookUrl={facebookUrl}
            material={material}
            pinterestUrl={pinterestUrl}
            twitterUrl={twitterUrl}
            variationsUrl={variationsUrl}
          />
        </SectionOverviewIntro>

        <S.RugWrapper>
          <Heading isVisuallyHidden={true}>Rug Picture</Heading>

          {rug}
        </S.RugWrapper>

        <S.MetaPortraitWrapper>
          <RugPageMeta
            color={color}
            facebookUrl={facebookUrl}
            isPortraitVariant={true}
            material={material}
            pinterestUrl={pinterestUrl}
            twitterUrl={twitterUrl}
            variationsUrl={variationsUrl}
          />
        </S.MetaPortraitWrapper>

        {footer}
      </Section>
    </>
  );
};

export default RugPage;
