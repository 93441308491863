import idx from 'idx';
import { graphql } from 'gatsby';

import buildRugDetailUrl from '../url/buildRugDetailUrl';
import buildRugName from '../rug/buildRugName';
import getSeoPicture from './getSeoPicture';

// Types
import { BuildRugPageSeoPropsFragment } from './__generated__/BuildRugPageSeoPropsFragment';

export const fragment = graphql`
  fragment BuildRugPageSeoPropsFragment on CMS_Rug {
    extra
    id
    name
    collection_jk {
      collectionPage {
        slug
      }
    }
    picture {
      ...SeoMetaPictureFragment
    }
  }
`;

const buildRugPageSeoProps = (rug: BuildRugPageSeoPropsFragment) => {
  const collectionSlug = idx(rug, _ => _.collection_jk.collectionPage.slug);

  if (!collectionSlug) {
    return {};
  }

  const name = buildRugName({ extra: rug.extra, name: rug.name });
  const picture = idx(rug, _ => _.picture) || null;

  const canonicalUrl = buildRugDetailUrl({ collectionSlug, rugId: rug.id });
  const image = getSeoPicture({ alt: name, picture });

  return {
    canonicalUrl,
    image,
    openGraphTitle: name,
    openGraphType: 'product',
    title: name,
  };
};

export default buildRugPageSeoProps;
