import React from 'react';

import * as S from './styles';

// Types
import { HTMLAttributes } from 'react';

type Props = {
  state?: any;
  url: string;
} & HTMLAttributes<HTMLAnchorElement>;

const RugVariationsButton = ({ state, url, ...rest }: Props) => {
  return (
    <S.Button state={state} url={url} {...rest}>
      Show all variations
    </S.Button>
  );
};

export default RugVariationsButton;
