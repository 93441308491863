import styled from '../../../utils/styling/styled';

import Section from '../../Heading/Section';
import styleUtils from '../../../utils/styling/styleUtils';

export const MetaPortraitWrapper = styled.div`
  display: none;

  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.padding.horizontal.both};

    display: block;
  }
`;

export const RugWrapper = styled(Section)`
  /* Flex Container */
  align-items: flex-start;
  display: flex;

  background-color: ${props => props.theme.color.white};

  ${styleUtils.media.orientation.landscape} {
    /* Flex Container */
    flex-direction: row;
    flex: none;

    margin-left: -1px;

    padding-top: calc(4rem + 90px);

    ${styleUtils.media.desktop.large} {
      padding-top: calc(4rem + 120px);
    }
  }

  ${styleUtils.media.orientation.portrait} {
    /* Flex Container */
    align-items: center;
    flex-direction: column;
    flex: none;

    margin-top: -1px;

    ${styleUtils.media.tablet.small} {
      /* Flex Container */
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
`;
