import React from 'react';
import idx from 'idx';
import { graphql } from 'gatsby';

import RugPage from '../../../components/Rug/RugPage';
import RugPageBreadcrumb from '../../../utils/seo/RugPageBreadcrumb';
import RugPageRugContainer from './RugPageRug';
import RugStructuredData from '../../../utils/seo/RugStructuredData';
import SEOContainer from '../../Layout/SEO';
import SectionFooterShowroomsDefaultContainer from '../../Section/SectionFooterShowroomsDefault';
import SectionOverviewIntroAppInfoContainer from '../../Section/SectionOverviewIntro/SectionOverviewIntroAppInfo';
import TitleHeaderPortraitContainer from '../../TitleHeader/TitleHeaderPortrait';
import buildCollectionOverviewUrl from '../../../utils/url/buildCollectionOverviewUrl';
import buildFacebookRugShareUrl from '../../../utils/url/buildFacebookRugShareUrl';
import buildPinterestRugShareUrl from '../../../utils/url/buildPinterestRugShareUrl';
import buildRugColor from '../../../utils/rug/buildRugColor';
import buildRugMaterial from '../../../utils/rug/buildRugMaterial';
import buildRugName from '../../../utils/rug/buildRugName';
import buildRugPageSeoProps from '../../../utils/seo/buildRugPageSeoProps';
import buildTwitterRugShareUrl from '../../../utils/url/buildTwitterRugShareUrl';
import getPictureShareUrl from '../../../utils/image/getPictureShareUrl';
import theme from '../../../utils/styling/theme';
import useHeaderContext from '../../../utils/context/HeaderContext';

import { getVariationsUrl } from './utils';

// Types
import { RugPageContainerFragment } from './__generated__/RugPageContainerFragment';

export const fragment = graphql`
  fragment RugPageContainerFragment on CMS_Rug {
    ...BuildRugPageSeoPropsFragment
    ...RugPageBreadcrumbFragment
    ...RugPageRugContainerFragment
    ...RugStructuredDataFragment
    created_at
    color
    extra
    id
    material1
    material2
    material3
    name
    searchColor1
    searchColor2
    searchColor3
    collection_jk {
      collectionPage {
        slug
      }
    }
    primaryRug {
      id
      isVisibleOnWebsite
    }
    secondaryRugs {
      id
      isVisibleOnWebsite
    }
  }
`;

type Props = {
  rug: RugPageContainerFragment;
};

const RugPageContainer = ({ rug }: Props) => {
  const seoProps = buildRugPageSeoProps(rug);

  const { setHeader } = useHeaderContext();

  const appInfo = <SectionOverviewIntroAppInfoContainer />;
  const appInfoPortrait = <SectionOverviewIntroAppInfoContainer isPortraitVariant={true} />;
  const footer = <SectionFooterShowroomsDefaultContainer backgroundColor={theme.color.white} textColor={theme.color.black} />;
  const picture = idx(rug, _ => _.picture.file.childImageSharp.fluid);
  const titleHeaderPortrait = <TitleHeaderPortraitContainer />;

  const collectionSlug = idx(rug, _ => _.collection_jk.collectionPage.slug);
  const rugColor = buildRugColor(rug);
  const rugContainer = <RugPageRugContainer rug={rug} />;
  const rugMaterial = buildRugMaterial(rug);
  const rugName = buildRugName({ extra: rug.extra, name: rug.name });

  React.useEffect(() => {
    if (!!collectionSlug) {
      const nextBackButtonTitle = 'Back to collection overview';
      const nextBackButtonUrl = buildCollectionOverviewUrl(collectionSlug);

      setHeader({ backButtonTitle: nextBackButtonTitle, backButtonUrl: nextBackButtonUrl, title: rugName });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionSlug, rugName]);

  if (!collectionSlug || !picture) {
    return null;
  }

  const variationsUrl = getVariationsUrl({ collectionSlug, ...rug });

  const pictureShareUrl = getPictureShareUrl(picture);

  const facebookUrl = buildFacebookRugShareUrl({ collectionSlug, pictureShareUrl, rugId: rug.id });
  const pinterestUrl = buildPinterestRugShareUrl({ collectionSlug, pictureShareUrl, rugId: rug.id, rugName });
  const twitterUrl = buildTwitterRugShareUrl({ collectionSlug, pictureShareUrl, rugId: rug.id, rugName });

  return (
    <>
      {/* SEO */}
      <RugPageBreadcrumb rug={rug} />
      <RugStructuredData rug={rug} />
      <SEOContainer {...seoProps} />

      <RugPage
        appInfo={appInfo}
        appInfoPortrait={appInfoPortrait}
        color={rugColor}
        facebookUrl={facebookUrl}
        footer={footer}
        material={rugMaterial}
        pinterestUrl={pinterestUrl}
        rug={rugContainer}
        title={seoProps.title}
        titleHeaderPortrait={titleHeaderPortrait}
        twitterUrl={twitterUrl}
        variationsUrl={variationsUrl}
      />
    </>
  );
};

export default RugPageContainer;
