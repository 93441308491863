import React from 'react';

import Link from '../../../Link';
import RugFacebookButton from '../../RugItems/RugFacebookButton';
import RugPinterestButton from '../../RugItems/RugPinterestButton';
import RugTwitterButton from '../../RugItems/RugTwitterButton';
import RugVariationsButton from '../../RugItems/RugVariationsButton';
import notNullOrUndefined from '../../../../utils/misc/notNullOrUndefined';
import routes from '../../../../config/routes';

import * as S from './styles';

// Types

type Props = {
  color: string | null;
  facebookUrl: string | null;
  isPortraitVariant?: boolean;
  material: string | null;
  pinterestUrl: string | null;
  twitterUrl: string | null;
  variationsUrl?: string | null;
};

const RugPageMeta = ({ color, facebookUrl, isPortraitVariant = false, material, pinterestUrl, twitterUrl, variationsUrl }: Props) => {
  return (
    <S.Wrapper aria-hidden={!isPortraitVariant} isPortraitVariant={isPortraitVariant}>
      {(!!color || !!material) && (
        <S.MetaList>
          {!!color && (
            <S.MetaListItem>
              <S.MetaTitle>Color</S.MetaTitle>

              <S.MetaText>{color}</S.MetaText>
            </S.MetaListItem>
          )}

          {!!material && (
            <S.MetaListItem>
              <S.MetaTitle>Material</S.MetaTitle>

              <S.MetaText>{material}</S.MetaText>
            </S.MetaListItem>
          )}
        </S.MetaList>
      )}
      {notNullOrUndefined(variationsUrl) && (
        <>
          <br />
          <RugVariationsButton state={{ isInternalLink: true }} url={variationsUrl} />
        </>
      )}
      <br /> Every Jan Kath carpet can be individually designed in terms of size, format, and materials. Even the collections can be
      combined with each other using a kind of modular system.{' '}
      <Link style={{ textDecoration: 'underline' }} url={routes.globalDistribution}>
        Find your nearest showroom
      </Link>
      <br />
      <S.ButtonGroup>
        {!!facebookUrl && <RugFacebookButton asExternal={true} url={facebookUrl} />}
        {!!twitterUrl && <RugTwitterButton asExternal={true} url={twitterUrl} />}
        {!!pinterestUrl && <RugPinterestButton asExternal={true} url={pinterestUrl} />}
      </S.ButtonGroup>
    </S.Wrapper>
  );
};

export default RugPageMeta;
