import React from 'react';

// Types
import { SVGAttributes } from 'react';

type Props = {} & SVGAttributes<SVGElement>;

const TwitterIcon = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121 100" version="1.1" {...props}>
      <path d="M 108.058594 25.175781 C 108.128906 26.238281 108.128906 27.304688 108.128906 28.378906 C 108.128906 61.136719 83.398438 98.910156 38.175781 98.910156 L 38.175781 98.886719 C 24.8125 98.910156 11.734375 95.050781 0.488281 87.777344 C 2.429688 88.011719 4.382812 88.132812 6.339844 88.136719 C 17.410156 88.144531 28.164062 84.398438 36.875 77.503906 C 26.355469 77.300781 17.128906 70.386719 13.90625 60.289062 C 17.589844 61.007812 21.390625 60.859375 25.003906 59.863281 C 13.535156 57.523438 5.28125 47.367188 5.28125 35.566406 C 5.28125 35.457031 5.28125 35.355469 5.28125 35.25 C 8.699219 37.171875 12.527344 38.238281 16.441406 38.355469 C 5.636719 31.074219 2.308594 16.585938 8.832031 5.257812 C 21.316406 20.742188 39.730469 30.15625 59.503906 31.152344 C 57.523438 22.546875 60.226562 13.523438 66.617188 7.46875 C 76.519531 -1.914062 92.09375 -1.433594 101.402344 8.546875 C 106.90625 7.453125 112.1875 5.414062 117.015625 2.527344 C 115.179688 8.265625 111.339844 13.140625 106.207031 16.234375 C 111.082031 15.660156 115.839844 14.339844 120.328125 12.335938 C 117.027344 17.324219 112.867188 21.664062 108.058594 25.175781 Z M 108.058594 25.175781 " />
    </svg>
  );
};

export default TwitterIcon;
