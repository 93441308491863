import styled from '../../../../utils/styling/styled';

import LocalLink from '../../../Link';
import PinterestIcon from '../../../SVG/PinterestIcon';

export const Button = styled(LocalLink)`
  /* Flex Container */
  align-items: center;
  display: flex;
  flex-direction: row;

  border-radius: 0.25rem;

  height: 30px;
  width: 30px;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  transition: background-color 300ms ease-in-out;

  background-color: ${props => props.theme.color.grey};

  svg {
    #pinterest {
      transition: fill 300ms ease-in-out;
    }
  }

  &:hover {
    background-color: ${props => props.theme.color.black};

    svg {
      #pinterest {
        fill: ${props => props.theme.color.black};
      }
    }
  }
`;

export const Icon = styled(PinterestIcon)`
  width: 20px;
`;
