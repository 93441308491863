import React from 'react';

import MediaImageFluid from '../../../MediaImage/MediaImageFluid';
import RugNewBadge from '../../RugItems/RugNewBadge';

import * as S from './styles';

// Types
import { CMS_ENUM_RUG_AWARD } from '../../../../../types/global';
import { FluidImage } from '../../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';

type Props = {
  award?: CMS_ENUM_RUG_AWARD | null;
  caption?: string | null;
  id: string;
  isNew: boolean;
  picture: FluidImage;
};

const RugPageRug = React.forwardRef<HTMLDivElement, Props>(({ award, caption, id, isNew, picture }: Props, ref) => {
  return (
    <S.Wrapper id={id} ref={ref}>
      <MediaImageFluid caption={caption} fadeIn={false} loading="eager" image={picture} vh={70} />

      <S.Award award={award} />

      {isNew && <RugNewBadge />}
    </S.Wrapper>
  );
});

export default RugPageRug;
