import styled from '../../../../utils/styling/styled';

import LocalLink from '../../../Link';

export const Button = styled(LocalLink)`
  /* Flex Container */
  align-items: center;
  display: flex;
  flex-direction: row;

  border-radius: 0.25rem;
  height: 100%;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  transition: background-color 300ms ease-in-out;

  background-color: ${props => props.theme.color.brand};

  color: ${props => props.theme.color.white};

  &:hover {
    background-color: ${props => props.theme.color.brandDark};
  }
`;
