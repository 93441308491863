import encodeData from './encodeData';
import buildRugDetailUrl from './buildRugDetailUrl';

// ?url=https%3A%2F%2Fwww.jan-kath.com%2Fcollection%2Fspacecrafted%2Foverview%2Fdetail%2F1177-Moon%201-&media=https%3A%2F%2Fcdn.jan-kath.com%2Fdata%2Fsmall%2FSpacecrafted_1501505_Moon1_250x300cm.jpg&description=Moon%201

const TWITTER_CREATE_BASE_URL = 'https://twitter.com/intent/tweet';

type BuildTwitterRugShareUrlInput = {
  collectionSlug: string;
  rugName: string;
  rugId: string;
  pictureShareUrl: string | null;
};

const buildTwitterRugShareUrl = ({ collectionSlug, rugId, rugName, pictureShareUrl }: BuildTwitterRugShareUrlInput) => {
  if (!pictureShareUrl) {
    return null;
  }

  const rugDetailUrl = `https://jan-kath.com${buildRugDetailUrl({ collectionSlug, rugId })}`;
  const description = `${rugName} by Jan Kath`;

  const encodedData = encodeData({
    text: description,
    url: rugDetailUrl,
  });

  const twitterUrl = `${TWITTER_CREATE_BASE_URL}?${encodedData}`;

  return twitterUrl;
};

export default buildTwitterRugShareUrl;
