import buildRugDetailUrl from './buildRugDetailUrl';

// ?url=https%3A%2F%2Fwww.jan-kath.com%2Fcollection%2Fspacecrafted%2Foverview%2Fdetail%2F1177-Moon%201-&media=https%3A%2F%2Fcdn.jan-kath.com%2Fdata%2Fsmall%2FSpacecrafted_1501505_Moon1_250x300cm.jpg&description=Moon%201

const FACEBOOK_CREATE_BASE_URL = 'https://www.facebook.com/sharer/sharer.php';

type BuildFacebookRugShareUrlInput = {
  collectionSlug: string;
  rugId: string;
  pictureShareUrl: string | null;
};

const buildFacebookRugShareUrl = ({ collectionSlug, rugId, pictureShareUrl }: BuildFacebookRugShareUrlInput) => {
  if (!pictureShareUrl) {
    return null;
  }

  const rugDetailUrl = `https://jan-kath.com${buildRugDetailUrl({ collectionSlug, rugId })}`;

  const facebookUrl = `${FACEBOOK_CREATE_BASE_URL}?u=${rugDetailUrl}`;

  return facebookUrl;
};

export default buildFacebookRugShareUrl;
