import React from 'react';
import idx from 'idx';
import { Generic, JSONLD } from 'react-structured-data';
import { graphql } from 'gatsby';

import buildRugName from '../rug/buildRugName';
import buildStructuredDataImageObject from './buildStructuredDataImageObject';
import { STRUCTURED_DATA_ORGANIZATION } from './constants';

// Types
import { RugStructuredDataFragment } from './__generated__/RugStructuredDataFragment';

export const fragment = graphql`
  fragment RugStructuredDataFragment on CMS_Rug {
    extra
    id
    name
    picture {
      ...SeoStructuredDataPictureFragment
    }
  }
`;

type Props = {
  rug: RugStructuredDataFragment;
};

const RugStructuredData = ({ rug }: Props) => {
  const rugName = buildRugName({ extra: rug.extra, name: rug.name });

  const fourToThree = idx(rug, _ => _.picture.file.childImageSharp.fourToThree.src) || null;
  const oneToOne = idx(rug, _ => _.picture.file.childImageSharp.oneToOne.src) || null;
  const sixteenToNine = idx(rug, _ => _.picture.file.childImageSharp.sixteenToNine.src) || null;

  const altText = `Picture of ${rugName}`;
  const caption = `${rugName} – by Jan Kath`;

  const fourToThreeImageObject = buildStructuredDataImageObject({
    altText,
    caption,
    // Defined in `src/utils/graphql/assetFragments.tsx`
    height: 1200,
    url: fourToThree,
    // Defined in `src/utils/graphql/assetFragments.tsx`
    width: 1200,
  });

  const oneToOneImageObject = buildStructuredDataImageObject({
    altText,
    caption,
    // Defined in `src/utils/graphql/assetFragments.tsx`
    height: 900,
    url: oneToOne,
    // Defined in `src/utils/graphql/assetFragments.tsx`
    width: 900,
  });

  const sixteenToNineImageObject = buildStructuredDataImageObject({
    altText,
    caption,
    // Defined in `src/utils/graphql/assetFragments.tsx`
    height: 900,
    url: sixteenToNine,
    // Defined in `src/utils/graphql/assetFragments.tsx`
    width: 1600,
  });

  const schema = {
    brand: STRUCTURED_DATA_ORGANIZATION,
    image: [oneToOneImageObject, fourToThreeImageObject, sixteenToNineImageObject],
    name: rugName,
    sku: rug.id,
  };

  return (
    <JSONLD dangerouslyExposeHtml={true}>
      <Generic jsonldtype="Product" schema={schema} />
    </JSONLD>
  );
};

export default RugStructuredData;
