import React from 'react';
import idx from 'idx';
import { graphql } from 'gatsby';

import LayoutContainer from '../containers/Layout';
import RugPageContainer from '../containers/Rug/RugPage';
import theme from '../utils/styling/theme';

// Types
import { RugPageTemplateQuery } from './__generated__/RugPageTemplateQuery';
import { PageRendererProps } from 'gatsby';

export const pageQuery = graphql`
  query RugPageTemplateQuery($where: CMS_JSON!) {
    cms {
      rugs(where: $where) {
        ...RugPageContainerFragment
        secondaryRugs {
          ...RugPageContainerFragment
        }
        collection_jk {
          collectionPage {
            slug
          }
        }
      }
    }
  }
`;

type Props = {
  data: RugPageTemplateQuery;
} & PageRendererProps;

const RugPageTemplate = ({ data }: Props) => {
  if (!data.cms.rugs || data.cms.rugs.length !== 1) {
    throw Error('Something went wrong');
  }

  const [rug] = data.cms.rugs;
  const collectionSlug = idx(rug, _ => _.collection_jk.collectionPage.slug);

  if (!rug || !collectionSlug) {
    throw Error('Something went wrong');
  }

  return (
    <LayoutContainer backgroundColor={theme.color.white} logoColor={theme.color.brand}>
      <RugPageContainer rug={rug} />
    </LayoutContainer>
  );
};

export default RugPageTemplate;
