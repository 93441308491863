import React from 'react';
import idx from 'idx';
import { Generic, JSONLD } from 'react-structured-data';
import { graphql } from 'gatsby';

import buildBreadcrumbListItem from './buildBreadcrumbListItem';
import buildCollectionOverviewTitle from './buildCollectionOverviewTitle';
import buildCollectionOverviewUrl from '../url/buildCollectionOverviewUrl';
import buildCollectionPageUrl from '../url/buildCollectionPageUrl';
import buildRugDetailUrl from '../url/buildRugDetailUrl';
import buildRugName from '../rug/buildRugName';
import { STRUCTURED_DATA_HOMEPAGE_BREADCRUMB_ITEM } from './constants';

// Types
import { RugPageBreadcrumbFragment } from './__generated__/RugPageBreadcrumbFragment';

export const fragment = graphql`
  fragment RugPageBreadcrumbFragment on CMS_Rug {
    extra
    id
    name
    collection_jk {
      name
      collectionPage {
        slug
        title
      }
    }
  }
`;

type Props = {
  rug: RugPageBreadcrumbFragment;
};

const RugPageBreadcrumb = ({ rug }: Props) => {
  const collectionName = idx(rug, _ => _.collection_jk.name);
  const collectionPageTitle = idx(rug, _ => _.collection_jk.collectionPage.title);
  const collectionSlug = idx(rug, _ => _.collection_jk.collectionPage.slug);

  if (!collectionName || !collectionPageTitle || !collectionSlug) {
    return null;
  }

  const collectionOverviewPageUrl = buildCollectionOverviewUrl(collectionSlug);
  const collectionOverviewTitle = buildCollectionOverviewTitle(collectionPageTitle);
  const collectionPageUrl = buildCollectionPageUrl(collectionSlug);
  const name = buildRugName({ extra: rug.extra, name: rug.name });
  const rugPageUrl = buildRugDetailUrl({ collectionSlug, rugId: rug.id });

  const collectionListItem = buildBreadcrumbListItem({ name: collectionName, position: 2, url: collectionPageUrl });
  const collectionOverviewListItem = buildBreadcrumbListItem({
    name: collectionOverviewTitle,
    position: 3,
    url: collectionOverviewPageUrl,
  });
  const rugListItem = buildBreadcrumbListItem({ name, position: 4, url: rugPageUrl });

  const schema = {
    itemListElement: [STRUCTURED_DATA_HOMEPAGE_BREADCRUMB_ITEM, collectionListItem, collectionOverviewListItem, rugListItem],
  };

  return (
    <JSONLD dangerouslyExposeHtml={true}>
      <Generic jsonldtype="BreadcrumbList" schema={schema} />
    </JSONLD>
  );
};

export default RugPageBreadcrumb;
