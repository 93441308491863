import styled from '../../../../utils/styling/styled';

import Heading from '../../../Heading';
import RugVariationsButton from '../../RugItems/RugVariationsButton';
import Section from '../../../Heading/Section';
import createOmitProps from '../../../../utils/styling/createOmitProps';
import styleUtils from '../../../../utils/styling/styleUtils';

export const MetaList = styled.ul`
  ${styleUtils.margin.vertical.none};
`;

export const MetaListItem = styled.li`
  ${styleUtils.margin.vertical.bottomHalf};

  &:last-of-type {
    ${styleUtils.margin.vertical.bottomNone};
  }
`;

export const MetaText = styled.span``;

export const MetaTitle = styled(Heading)`
  ${styleUtils.margin.vertical.none};

  ${styleUtils.font.weight.bold};

  text-transform: uppercase;
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const VariationLink = styled(RugVariationsButton)``;

type WrapperProps = {
  isPortraitVariant: boolean;
};

export const Wrapper = styled(Section, { shouldForwardProp: createOmitProps(['isPortraitVariant']) })<WrapperProps>`
  /* Flex Container */
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: relative;

  ${styleUtils.media.orientation.landscape} {
    display: ${props => (!props.isPortraitVariant ? 'flex' : 'none')};
    margin-right: 4rem;
    min-width: 25vw;
  }

  ${styleUtils.media.orientation.portrait} {
    display: ${props => (props.isPortraitVariant ? 'flex' : 'none')};

    width: 90vw;

    ${styleUtils.media.tablet.small} {
      flex: 1 1 32%;
    }
  }
`;
