import buildAbsoluteUrl from './buildAbsoluteUrl';
import { STRUCTURED_DATA_ORGANIZATION } from './constants';

type BuildStructuredDataImageObjectInput = {
  altText?: string | null;
  caption?: string | null;
  height: number;
  url?: string | null;
  width: number;
};

const buildStructuredDataImageObject = ({ altText, caption, height, url, width }: BuildStructuredDataImageObjectInput) => {
  if (!(!!altText || !!caption) || !url) {
    return {};
  }

  const schema = {
    '@context': 'http://schema.org',
    '@type': 'ImageObject',
    caption: caption || altText || undefined,
    copyrightHolder: STRUCTURED_DATA_ORGANIZATION,
    height,
    url: buildAbsoluteUrl(url),
    width,
  };

  return schema;
};

export default buildStructuredDataImageObject;
